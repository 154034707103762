import * as React from 'react';

import Button from '@mui/material/Button';
import { Box, CircularProgress, Typography } from '@mui/material';
import { WorkspaceContext } from '../providers/WorkspaceProvider';
import PrivateRoute from '../components/PrivateRoute';
import Sidebar from '../components/Sidebar';
import Layout from '../components/Layout';
import { useLicenseContext } from '../providers/LicenseContextProvider';
import { WorkspaceUserRole } from '../../api/workspaces';
import TeamTable from '../components/WorkspaceTeam/TeamTable';
import AddUserModal from '../components/WorkspaceTeam/AddUserModal';
import DeteleUserModal from '../components/WorkspaceTeam/DeleteUserModal';
import TransferUserModal from '../components/WorkspaceTeam/TransferUserModal';

const TeamScene: React.FC = () => {
  const {
    addUserToTeam, team, role, loading: workspaceLoading, removeUserFromTeam,
  } = React.useContext(WorkspaceContext);

  const { wsSubscription, isLoading: licenseLoading, isPro } = useLicenseContext();

  const [totalSeats, availableSeats] = React.useMemo(() => [
    wsSubscription?.quantity || 0,
    wsSubscription ? wsSubscription.quantity - team.length : 0,
  ], [wsSubscription, team]);

  const [addDialogOpen, setAddDialogOpen] = React.useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [transferDialogOpen, setTransferDialogOpen] = React.useState(false);

  const [newUserEmail, setNewUserEmail] = React.useState('');
  const [selectedEmail, setSelectedEmail] = React.useState('');
  const [selectedTransferEmail, setSelectedTransferEmail] = React.useState('');

  const handleOpenAddDialog = () => {
    setAddDialogOpen(true);
  };

  const handleCloseAddDialog = () => {
    setAddDialogOpen(false);
    setNewUserEmail('');
  };

  const handleAddUser = async () => {
    await addUserToTeam(newUserEmail);
    handleCloseAddDialog();
  };

  const handleOpenDeleteDialog = (email: string) => {
    setDeleteDialogOpen(true);
    setSelectedEmail(email);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleDeleteUser = async () => {
    await removeUserFromTeam(selectedEmail);
    handleCloseDeleteDialog();
  };

  const handleOpenTransferDialog = (email:string) => {
    setSelectedTransferEmail(email);
    setTransferDialogOpen(true);
  };

  const handleCloseTransferDialog = () => {
    setTransferDialogOpen(false);
  };

  return (
    <PrivateRoute>
      <Layout sidebar={<Sidebar />}>
        <Box display="flex" alignItems="center" justifyContent="space-between" mb={3}>
          <Typography fontSize={24} fontWeight={600}>
            Manage users
          </Typography>
          {role === WorkspaceUserRole.Admin
              && (
                <Box display="flex" alignItems="center" gap="16px">
                  <Typography color="rgba(130, 130, 130, 1)" fontSize="14px">
                    You can invite
                    {' '}
                    {availableSeats}
                    {' '}
                    user
                  </Typography>

                  <Button variant="contained" color="primary" onClick={handleOpenAddDialog} disabled={availableSeats <= 0 || !isPro}>
                    Invite user
                  </Button>
                </Box>
              )}
        </Box>

        {workspaceLoading || licenseLoading ? <CircularProgress /> : (
          <>
            {availableSeats < totalSeats && role === WorkspaceUserRole.Admin
              && (
              <Typography>
                <b>{availableSeats}</b>
                {' '}
                new members can join your workspace
                (workspace quota is
                {' '}
                {totalSeats}
                )
              </Typography>
              )}
            <TeamTable
              handleDeleteButton={handleOpenDeleteDialog}
              handleTransferButton={handleOpenTransferDialog}
            />
          </>
        )}

        <AddUserModal
          addDialogOpen={addDialogOpen}
          handleCloseAddDialog={handleCloseAddDialog}
          handleAddUser={handleAddUser}
          setNewUserEmail={setNewUserEmail}
          newUserEmail={newUserEmail}
        />
        <DeteleUserModal
          deleteDialogOpen={deleteDialogOpen}
          handleCloseDeleteDialog={handleCloseDeleteDialog}
          handleDeleteUser={handleDeleteUser}
        />
        <TransferUserModal
          tranferDialogOpen={transferDialogOpen}
          handleCloseTranferDialog={handleCloseTransferDialog}
          transferEmail={selectedTransferEmail}
        />
      </Layout>
    </PrivateRoute>
  );
};

export default TeamScene;
