import * as React from 'react';
import Layout from '../components/Layout';

const NotFoundScene: React.FC = () => (
  <Layout>
    <h1>Page not found</h1>
  </Layout>
);

export default NotFoundScene;
