/* eslint-disable react/require-default-props */
import * as React from 'react';
import { makeStyles } from '@mui/styles';
import {
  Box, Card, CardActions, CardContent, Typography, Button,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ValidatedNumberInput from '../../common/ValidatedNumberInput';
import PurchaseButton from '../PurchaseButton';
import ActivationCodeInput from './ActivationCodeInput';
import SubscriptionTypeSelect from './SubscriptionType';
import PlanAdvantages from './PlanAdvantages';
import usePlanCard from '../../../hooks/usePlanCard';

export interface IProps {
  name: string;
  prices: any;
  advantages?: string[];
  teamPlan?: boolean;
  activateButton?: boolean;
  monthlyPlanId: number;
  yearlyPlanId: number;
  lifetimePlanId: number;
  excludePlans?: string[];
  allOptionsDisabled?: boolean;
}

const useStyles = makeStyles(() => ({
  root: {
    width: 330,
    height: 418,
  },

  error: {
    position: 'absolute',
    top: '75px',
    left: '16px',
    color: 'rgba(235, 87, 87, 1)',
    fontSize: '12px !important',
    fontWeight: '500 !important',
  },
}));

export const PlanCard: React.FC<IProps> = (props: IProps) => {
  const classes = useStyles();
  const theme = useTheme();

  const {
    name,
    prices,
    advantages = [],
    teamPlan = false,
    activateButton = false,
    lifetimePlanId,
    yearlyPlanId,
    monthlyPlanId,
    excludePlans = [],
    allOptionsDisabled = false,
  } = props;

  const {
    activationCode,
    setActivationCode,
    error,
    setError,
    subscriptionType,
    handleChange,
    price,
    planId,
    seats,
    setSeats,
    activateCode,
    sub,
  } = usePlanCard({
    prices,
    teamPlan,
    excludePlans,
    yearlyPlanId,
    monthlyPlanId,
    lifetimePlanId,
  });

  return (
    <Card
      className={classes.root}
      sx={{
        padding: '24px 40px',
        filter: allOptionsDisabled ? 'opacity(0.5)' : 'none',
      }}
    >
      <CardContent sx={{ padding: '0' }}>
        <Typography sx={activateButton ? { marginBottom: '105px' } : { marginBottom: '4px' }} color={theme.palette.primary.dark} fontWeight={600} fontSize={18} gutterBottom>
          {name}
        </Typography>
        {!activateButton && (
          <Typography variant="h5" color={allOptionsDisabled ? 'rgba(189, 189, 189, 1)' : theme.palette.primary.main} fontWeight={600} fontSize={40}>
            {price}
            $
          </Typography>
        )}
        {activateButton && (
          <Typography variant="body2" mt="2px" color={theme.palette.primary.dark} fontSize={16} mb={!teamPlan && !activateButton && '115px'}>
            {sub ? 'Add a user via activation code' : 'Activate lifetime access via activation code'}
          </Typography>
        )}
      </CardContent>

      {teamPlan && (
        <Box display="flex" alignItems="center" gap={2} mt="24px" mb="16px" height="52px">
          <ValidatedNumberInput
            id="usersCount"
            variant="outlined"
            value={seats}
            onChange={setSeats}
            min={3}
            InputProps={{
              inputProps: {
                style: {
                  padding: '14.5px 4px',
                  width: '62px',
                  textAlign: 'right',
                },
              },
            }}
          />
          <Typography color={theme.palette.grey[50]}>users</Typography>
        </Box>
      )}

      {!teamPlan && !activateButton && <Typography mb="16px" mt="52px">1 user</Typography>}

      {activateButton && (
        <ActivationCodeInput
          activationCode={activationCode}
          setActivationCode={setActivationCode}
          error={error}
          setError={setError}
        />
      )}

      {!activateButton && (
        <SubscriptionTypeSelect
          subscriptionType={subscriptionType}
          handleChange={handleChange}
          excludePlans={excludePlans}
          allOptionsDisabled={allOptionsDisabled}
        />
      )}

      <CardActions sx={{ padding: '0', width: '100%', marginBottom: '24px' }}>
        {activateButton ? (
          <Button
            disabled={!activationCode}
            size="large"
            type="submit"
            variant="contained"
            color="primary"
            style={{ width: '100%', height: '44px' }}
            onClick={() => { activateCode(activationCode); }}
          >
            Activate
          </Button>
        ) : (
          <PurchaseButton productId={planId} disabled={allOptionsDisabled} seats={seats} />
        )}
      </CardActions>

      <PlanAdvantages advantages={advantages} />
    </Card>
  );
};

export default PlanCard;
