import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import { Button, Chip } from '@mui/material';
import { WorkspaceContext } from '../../providers/WorkspaceProvider';
import { WorkspaceUserRole } from '../../../api/workspaces';
import { AuthContext } from '../../providers/AuthProvider';

const useStyles = makeStyles(() => ({
  tableTitle: {
    color: 'rgba(135, 135, 151, 1) !important',
    fontWeight: '600 !important',
    fontSize: '14px !important',
    padding: '8px 16px !important',
  },
  button: {
    border: 'none',
    background: 'inherit',
    fontWeight: '500 !important',
    fontSize: '14px !important',
    minWidth: 'unset !important',
  },
  status: {
  },
}));

interface ITeamTable {
  handleDeleteButton: (email: string) => void;
  handleTransferButton: (email: string) => void;
}

export const TeamTable: React.FC<ITeamTable> = ({ handleDeleteButton, handleTransferButton }) => {
  const classes = useStyles();
  const { team, role } = React.useContext(WorkspaceContext);
  const { user: currentUser } = React.useContext(AuthContext);

  const status = 'Active';

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableTitle} align="left">Email</TableCell>
            <TableCell className={classes.tableTitle} align="left">Status</TableCell>
            {role === WorkspaceUserRole.Admin
              && (
                <TableCell className={classes.tableTitle} align="left">Actions</TableCell>
              )}

          </TableRow>
        </TableHead>
        <TableBody>
          {team.map((user) => (
            <TableRow
              sx={{ 'td,th': { border: 0 } }}
            >
              <TableCell align="left">
                {`${user.email}${user.email === currentUser.email ? ' (You)' : ''} ${user.role === WorkspaceUserRole.Admin ? '(Admin)' : ''}`}
              </TableCell>
              <TableCell align="left">
                <Chip
                  variant="filled"
                  label="Active"
                  className={classes.status}
                  style={status === 'Active' ? { backgroundColor: 'rgba(33, 150, 83, 1)', color: '#fff' } : { backgroundColor: 'rgba(242, 201, 76, 1)' }}

                />
              </TableCell>
              {role === WorkspaceUserRole.Admin && user.email !== currentUser.email
              && (
                <TableCell align="left" sx={{ display: 'flex' }}>
                  <Button className={classes.button} sx={{ color: 'rgba(60, 57, 197, 1)' }} onClick={() => handleTransferButton(user.email)}>
                    Transfer
                  </Button>
                  <Button className={classes.button} sx={{ color: 'rgba(235, 87, 87, 1)' }} onClick={() => handleDeleteButton(user.email)}>
                    Delete
                  </Button>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TeamTable;
