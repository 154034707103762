import * as React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface IDeleteUserModal {
  deleteDialogOpen:boolean,
  handleCloseDeleteDialog: () => void,
  handleDeleteUser: () => void,
}

export const DeteleUserModal: React.FC<IDeleteUserModal> = ({
  deleteDialogOpen, handleCloseDeleteDialog, handleDeleteUser,
}) => (
  <Dialog
    open={deleteDialogOpen}
    onClose={handleCloseDeleteDialog}
    PaperProps={{ sx: { width: 412, height: 240 } }}
  >
    <Box>
      <IconButton
        aria-label="close"
        onClick={handleCloseDeleteDialog}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
    </Box>
    <Box>
      <DialogTitle>Delete this user?</DialogTitle>
    </Box>

    <DialogContent>
      <DialogContentText sx={{ marginBottom: '16px' }}>
        This process cannot be undone
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleCloseDeleteDialog} color="primary">
        Cancel
      </Button>
      <Button onClick={handleDeleteUser} color="primary" variant="contained">
        Yes, delete
      </Button>
    </DialogActions>
  </Dialog>
);

export default DeteleUserModal;
