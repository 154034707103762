import * as React from 'react';
import useWorkspaceData from '../hooks/useWorkspaceData';
import {
  IWorkspace,
  IWorkspaceMember,
  updateWorkspaceName,
  addUserToWorkspace,
  removeUserFromWorkspace,
  WorkspaceUserRole,
  IFullWorkspaceData,
} from '../../api/workspaces';

export interface IWorkspaceContext {
  changeWorkspaceName: (name: string) => Promise<void>;
  selectWorkspace: (id: string) => void;
  addUserToTeam: (email: string) => Promise<void>;
  removeUserFromTeam: (email: string) => Promise<void>;
  userWorkspaces: IWorkspace[];
  id: string;
  name: string;
  loading: boolean;
  team: IWorkspaceMember[];
  role: WorkspaceUserRole;
  workspaceData: IFullWorkspaceData;
}

export const WorkspaceContext = React.createContext<IWorkspaceContext>({
  changeWorkspaceName: () => Promise.resolve(),
  addUserToTeam: () => Promise.resolve(),
  removeUserFromTeam: () => Promise.resolve(),
  selectWorkspace: () => null,
  id: null,
  name: null,
  userWorkspaces: [],
  team: [],
  loading: false,
  role: null,
  workspaceData: null,
});

export const WorkspaceProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const {
    workspaceData,
    fetchWorkspaceData,
    workspaceId,
    setSelectedWorkspaceId,
    userWorkspaces,
    loading,
    fetchUserWorkspaces,
  } = useWorkspaceData();

  const workspaceName = React.useMemo(() => workspaceData?.workspace?.name, [workspaceData]);
  const team = React.useMemo(() => workspaceData?.workspaceTeam || [], [workspaceData]);
  const role = React.useMemo(() => workspaceData?.role, [workspaceData]);

  const changeWorkspaceName = React.useCallback(async (name: string) => {
    if (!workspaceId) return;

    await updateWorkspaceName(workspaceId, name);
    await fetchWorkspaceData(workspaceId);
    await fetchUserWorkspaces();
  }, [workspaceId, fetchWorkspaceData, fetchUserWorkspaces]);

  const selectWorkspace = React.useCallback((id: string) => {
    setSelectedWorkspaceId(id);
  }, [setSelectedWorkspaceId]);

  const addUserToTeam = React.useCallback(async (email: string) => {
    if (!workspaceId) return;

    await addUserToWorkspace(workspaceId, email);
    await fetchWorkspaceData(workspaceId);
    await fetchUserWorkspaces();
  }, [workspaceId, fetchWorkspaceData, fetchUserWorkspaces]);

  const removeUserFromTeam = React.useCallback(async (email: string) => {
    if (!workspaceId) return;

    await removeUserFromWorkspace(workspaceId, email);
    await fetchWorkspaceData(workspaceId);
    await fetchUserWorkspaces();
  }, [workspaceId, fetchWorkspaceData, fetchUserWorkspaces]);

  const value: IWorkspaceContext = React.useMemo<IWorkspaceContext>(() => ({
    id: workspaceId,
    name: workspaceName,
    userWorkspaces,
    loading,
    team,
    role,
    workspaceData,
    changeWorkspaceName,
    selectWorkspace,
    addUserToTeam,
    removeUserFromTeam,
  }), [
    changeWorkspaceName,
    selectWorkspace,
    removeUserFromTeam,
    addUserToTeam,
    workspaceId,
    workspaceName,
    userWorkspaces,
    loading,
    team,
    role,
    workspaceData,
  ]);

  return (
    <WorkspaceContext.Provider value={value}>
      {children}
    </WorkspaceContext.Provider>
  );
};

export default WorkspaceProvider;
