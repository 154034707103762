/* eslint-disable react/require-default-props */
import React, { useState } from 'react';
import { TextField, TextFieldProps } from '@mui/material';

interface IValidatedNumberInput extends Omit<TextFieldProps, 'onChange' | 'value'> {
  value: number;
  onChange: (value) => void;
  min?: number;
  max?: number;
}

export const ValidatedNumberInput: React.FC<IValidatedNumberInput> = ({
  value, onChange, min = Number.MIN_SAFE_INTEGER, max = Number.MAX_SAFE_INTEGER, ...rest
}) => {
  const [inputValue, setInputValue] = useState(value.toString());

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: input } = e.target;
    if (/^-?\d*$/.test(input)) {
      setInputValue(input);
      const numericValue = Number(input);
      if (!Number.isNaN(numericValue) && numericValue >= min && numericValue <= max) {
        onChange(numericValue);
      }
    }
  };

  const handleBlur = () => {
    const numericValue = Number(inputValue);
    if (Number.isNaN(numericValue) || numericValue < min) {
      setInputValue(min.toString());
      onChange(min);
    } else if (numericValue > max) {
      setInputValue(max.toString());
      onChange(max);
    }
  };

  return (
    <TextField
      value={inputValue}
      onChange={handleInputChange}
      onBlur={handleBlur}
      type="number"
      InputProps={{
        inputProps: {
          min,
          max,
        },
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    />
  );
};

export default ValidatedNumberInput;
