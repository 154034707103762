import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  IFullWorkspaceData,
  IWorkspace,
  getFullWorkspaceData,
  getUserWorkspaces,
} from '../../api/workspaces';
import { AuthContext } from '../providers/AuthProvider';
import useStorage from './useStorage';

export const useWorkspaceData = () => {
  const [userWorkspaces, setUserWorkspaces] = useState<IWorkspace[]>([]);
  const [workspaceData, setWorkspaceData] = useState<IFullWorkspaceData>(null);
  const [loading, setLoading] = useState(true);
  const [selectedWorkspaceId, setSelectedWorkspaceId] = useStorage('selectedWorkspace', null);

  const { user } = React.useContext(AuthContext);

  const email = user?.email;

  const fetchUserWorkspaces = React.useCallback(async () => {
    if (!email) return;
    const workspaces = await getUserWorkspaces(email);
    setUserWorkspaces(workspaces);
  }, [email]);

  const fetchWorkspaceData = React.useCallback(async (id: string) => {
    if (!email || !id) return;
    setLoading(true);
    const data = await getFullWorkspaceData(email, id);

    setWorkspaceData(data);
    setLoading(false);
  }, [setLoading, setWorkspaceData, email]);

  useEffect(() => {
    fetchUserWorkspaces();
  }, [fetchUserWorkspaces]);

  const workspaceId = React.useMemo(
    () => {
      if (userWorkspaces.length > 0 && !userWorkspaces.find((w) => w.id === selectedWorkspaceId)) {
        setSelectedWorkspaceId(userWorkspaces[0]?.id);
        return userWorkspaces[0]?.id;
      }

      return selectedWorkspaceId || userWorkspaces[0]?.id;
    },
    [userWorkspaces, selectedWorkspaceId, setSelectedWorkspaceId],
  );

  useEffect(() => {
    fetchWorkspaceData(workspaceId);
  }, [workspaceId, fetchWorkspaceData]);

  return {
    workspaceId,
    workspaceData,
    loading,
    userWorkspaces,
    fetchWorkspaceData,
    setSelectedWorkspaceId,
    fetchUserWorkspaces,
  };
};

export default useWorkspaceData;
