import { initializeApp, getApps, getApp } from 'firebase/app';
import { getAuth, browserLocalPersistence } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyAREy6mRcxMo2fPeGOxcrua5Vb8RTOf4vI',
  authDomain: 'stepsy-6f4f5.firebaseapp.com',
  projectId: 'stepsy-6f4f5',
  storageBucket: 'stepsy-6f4f5.appspot.com',
  messagingSenderId: '788033197015',
  appId: '1:788033197015:web:0e563ab657df0f81717192',
  measurementId: 'G-4GFZYBS9M6',
};

// Initialize Firebase
if (getApps().length === 0) {
  initializeApp(firebaseConfig);
}

// export const db = getFirestore(app);
const auth = getAuth(getApp());
auth.setPersistence(browserLocalPersistence);

export { auth };
export const db = getFirestore(getApp());

export default auth;
