import * as React from 'react';

import {
  Box,
  Button,
  Link,
  Typography,
} from '@mui/material';
import { useLicenseContext } from '../../providers/LicenseContextProvider';
import { dateDiffInDays, isLifetime } from '../../../common/utils';
import {
  PADDLE_LIFETIME_ID,
  PADDLE_MONTHLY_ID,
  PADDLE_YEARLY_ID,
  WS_LIFETIME_PLAN_ID,
  WS_MONTHLY_PLAN_ID,
  WS_YEARLY_PLAN_ID,
} from '../../../api/paddle';

export interface IProps {
}

export const SubscriptionMessage: React.FC<IProps> = () => {
  const {
    subscription, trialDaysLeft, wsSubscription, isLoading,
  } = useLicenseContext();

  const daysLeft = dateDiffInDays(
    new Date(subscription?.subscriptionEndDate),
    new Date(),
  );
  const [subName, setSubName] = React.useState('');

  const sub = wsSubscription || subscription;

  React.useEffect(() => {
    switch (sub?.subscriptionPlanId) {
      case PADDLE_LIFETIME_ID.toString():
        setSubName('Personal (lifetime)');
        break;

      case PADDLE_YEARLY_ID.toString():
        setSubName('Personal (annual)');
        break;
      case PADDLE_MONTHLY_ID.toString():
        setSubName('Personal (monthly)');
        break;
      case WS_LIFETIME_PLAN_ID.toString():
        setSubName('Team (lifetime)');
        break;
      case WS_YEARLY_PLAN_ID.toString():
        setSubName('Team (annual)');
        break;
      case WS_MONTHLY_PLAN_ID.toString():
        setSubName('Team (monthly)');
        break;
      default:
        setSubName('');
    }
  }, [sub?.subscriptionPlanId]);

  let message = 'Your current subscription plan - Free. Upgrade your plan to have access for the features';

  function addDays(date, days) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  function formatDate(date) {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  }

  const today = new Date();

  if (sub) {
    if (daysLeft > 0 || isLifetime(sub)) {
      message = `Your current subscription plan - ${subName}`;
    }
  } else if (trialDaysLeft > 0) {
    const trialExpirationDate = addDays(today, trialDaysLeft);
    message = `Your current subscription plan - Trial. Expiration date ${formatDate(trialExpirationDate)}`;
  }

  const showButtons = subName && trialDaysLeft <= 0
  && sub?.subscriptionPlanId !== PADDLE_LIFETIME_ID.toString()
  && sub?.subscriptionPlanId !== WS_LIFETIME_PLAN_ID.toString();

  return (
    <Box>
      {!isLoading
      && (
      <Box width="max-content" margin="16px 0 24px" sx={{ backgroundColor: 'rgba(230, 229, 247, 1)', borderRadius: '8px', padding: '10px 12px' }}>
        <Typography fontSize="14px">
          {message}
          {
            showButtons
            && (
            <>
              <Button>
                <Link href={subscription?.subscriptionUpdateUrl} sx={{ textDecoration: 'none' }}>
                  Update payment method
                </Link>
              </Button>
              <Button>
                <Link sx={{ textDecoration: 'none', color: 'rgba(235, 87, 87, 1)' }} href={subscription?.subscriptionCancelUrl}>
                  Cancel subscription
                </Link>
              </Button>
            </>
            )
          }

        </Typography>
      </Box>
      )}
    </Box>
  );
};

export default SubscriptionMessage;
