import { Alert, Snackbar } from '@mui/material';
import * as React from 'react';
import snackbar from '../../assets/icons/snackbars.svg';

interface IMessagesContext {
  addSuccessMsg: (msg: string) => void;
  addErrorMsg: (msg: string) => void;
}

export const MessagesContext = React.createContext({
  addSuccessMsg: (msg: string) => {},
  addErrorMsg: (msg: string) => {},
});

export const MessagesProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [severity, setSeverity] = React.useState<'success' | 'error'>('success');

  const handleClose = () => {
    setOpen(false);
  };

  const addSuccessMsg = React.useCallback((msg: string) => {
    setMessage(msg);
    setSeverity('success');
    setOpen(true);
  }, []);

  const addErrorMsg = React.useCallback((msg: string) => {
    setMessage(msg);
    setSeverity('error');
    setOpen(true);
  }, []);

  const value: IMessagesContext = React.useMemo<IMessagesContext>(() => ({
    addSuccessMsg,
    addErrorMsg,
  }), [addSuccessMsg, addErrorMsg]);

  return (
    <MessagesContext.Provider value={value}>
      {children}
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert sx={{ backgroundColor: 'rgba(46, 49, 51, 1)', color: 'rgba(255, 255, 255, 1)' }} severity={severity} onClose={handleClose} icon={<img src={snackbar} alt="done" />}>
          {message}
        </Alert>
      </Snackbar>
    </MessagesContext.Provider>
  );
};

export default MessagesProvider;
