import * as React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface IAddUserModal {
  addDialogOpen:boolean,
  handleCloseAddDialog: () => void,
  handleAddUser: () => void,
  setNewUserEmail: (value: string) => void,
  newUserEmail: string,
}

export const AddUserModal: React.FC<IAddUserModal> = ({
  addDialogOpen, handleCloseAddDialog, handleAddUser, setNewUserEmail, newUserEmail,
}) => (
  <Dialog
    open={addDialogOpen}
    onClose={handleCloseAddDialog}
    PaperProps={{ sx: { width: 412, height: 310 } }}
  >
    <Box>
      <IconButton
        aria-label="close"
        onClick={handleCloseAddDialog}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
    </Box>
    <Box>
      <DialogTitle>Invite user</DialogTitle>
    </Box>

    <DialogContent>
      <DialogContentText sx={{ marginBottom: '16px' }}>
        Type user email to send invitation
      </DialogContentText>
      <TextField
        label="Enter email"
        value={newUserEmail}
        onChange={(e) => setNewUserEmail(e.target.value)}
        fullWidth
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={handleCloseAddDialog} color="primary">
        Cancel
      </Button>
      <Button onClick={handleAddUser} color="primary" variant="contained">
        Send invite
      </Button>
    </DialogActions>
  </Dialog>
);

export default AddUserModal;
