import * as React from 'react';
import { Box, Button, Typography } from '@mui/material';

import { makeStyles } from '@mui/styles';

import GoogleIcon from '@mui/icons-material/Google';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../providers/AuthProvider';
import Layout from '../components/Layout';

import whatcanimg from '../../assets/img/whatcando.webp';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    padding: '48px 0',
  },

  form: {
    marginTop: '20px',
  },

  title: {
    display: 'block',
    textAlign: 'center',
    marginBottom: '20px !important',
  },
}));

const LoginScene: React.FC = () => {
  const classes = useStyles();
  const { signInWithGoogle, user } = React.useContext(AuthContext);
  const navigate = useNavigate();

  const signIn = React.useCallback(() => {
    signInWithGoogle();
  }, [signInWithGoogle]);

  React.useEffect(() => {
    if (user) {
      navigate('/get-started');
    }
  }, [navigate, user]);

  return (
    <Layout>
      <Box className={classes.root}>
        <Box sx={{ width: '600px', borderRight: '1px solid #E5EAF2', padding: '48px' }}>
          <h1>
            Document your steps in no time
          </h1>

          <p>
            Simple yet powerful software to create software operating procedures, process tasks, step-by-step guides and manuals and share with your coleagues and customers
          </p>

          <Box sx={{ width: '100%' }}>
            <img src={whatcanimg} alt="" style={{ width: '100%' }} />
          </Box>
        </Box>

        <Box className={classes.form} sx={{ width: '600px', padding: '48px', textAlign: 'center' }}>
          <Typography variant="h5" className={classes.title} fontWeight="bold">Sign in</Typography>
          <Button startIcon={<GoogleIcon />} variant="contained" onClick={signIn} size="large">
            Sign in with Google
          </Button>
        </Box>
      </Box>
    </Layout>
  );
};

export default LoginScene;
