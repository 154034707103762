import { Alert, Button, Snackbar } from '@mui/material';
import * as React from 'react';
import { AuthContext } from '../../providers/AuthProvider';
import { WorkspaceContext } from '../../providers/WorkspaceProvider';
import { isWorkspacePlan } from '../../../api/paddle';

declare let Paddle;

export interface IProps {
  productId: number;
  disabled: boolean;
  seats: number
}

export const PurchaseButton: React.FC<IProps> = (props: IProps) => {
  const { productId, disabled, seats } = props;

  const [msgOpen, setMsgOpen] = React.useState(false);

  const { user } = React.useContext(AuthContext);
  const { id } = React.useContext(WorkspaceContext);

  const isWorkspace = isWorkspacePlan(productId);

  const sendEmailNotification = async () => {
    const today = new Date().toISOString().split('T')[0];

    const payload = {
      email: user.email,
      phone: '+123456789',
      product_name: `${productId}`,
      product_price: 'product_price value',
      order_date: today,
    };

    try {
      await fetch(
        'https://events.sendpulse.com/events/id/4e64b25ebcbf456a8c74804f92089278/8756047',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        },
      );
    } catch (error) {
      console.error('Error sending purchase details:', error);
    }
  };

  const onCheckOut = () => {
    Paddle.Checkout.open({
      product: productId,
      email: user.email,
      disableLogout: true,
      allowQuantity: false,
      quantity: isWorkspace ? seats : 1,
      closeCallback: () => {},
      successCallback: () => {
        setMsgOpen(true);
        sendEmailNotification();
      },
      passthrough: JSON.stringify({ workspaceId: id }),
    });
  };

  return (
    <>
      <Snackbar
        open={msgOpen}
        autoHideDuration={6000}
        onClose={() => setMsgOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setMsgOpen(false)} severity="success">
          Stepsy Pro subscription has been purchased successfully. It will take
          a couple of minutes to update your license.
        </Alert>
      </Snackbar>
      <Button
        disabled={disabled}
        sx={{ width: '100%', height: '44px' }}
        variant="contained"
        color="primary"
        onClick={onCheckOut}
      >
        Purchase
      </Button>
    </>
  );
};

export default PurchaseButton;
