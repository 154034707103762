import React from 'react';
import { Box, Typography } from '@mui/material';
import check from '../../../../assets/icons/check.svg';

interface IPlanAdvantages {
  advantages
}

export const PlanAdvantages: React.FC<IPlanAdvantages> = ({ advantages }) => (
  <Box>
    {advantages?.map((item) => (
      <Box display="flex" gap={1} mb={1} key={item}>
        <img src={check} alt="check" />
        <Typography color="primary.dark" fontWeight={500} fontSize={14}>
          {item}
        </Typography>
      </Box>
    ))}
  </Box>
);

export default PlanAdvantages;
