import * as React from 'react';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import Header from '../Header';

export interface ILayoutProps {
  children?: React.ReactNode;
  sidebar?: React.ReactNode;
}

const useStyles = makeStyles(() => ({
  layout: {
    display: 'flex',
    alignItems: 'flex-start',
  },

  main: {
    width: 'calc(100vw - 270px)',
    height: '100vh',
    overflow: 'auto',
    background: '#F7F7FD',
  },

  content: {
    padding: '32px 40px',
  },

  noSidebar: {
    width: '100%',
  },

  sidebar: {
    height: '100vh',
    width: '270px',
    borderRight: '1px solid #E5EAF2',
    backgroundColor: '#3C39C5',
  },
}));

const Layout: React.FC<any> = ({ children, sidebar }) => {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.layout}>
        { sidebar ? (
          <div className={classes.sidebar}>
            {sidebar}
          </div>
        ) : null }
        <main className={clsx(classes.main, !sidebar && classes.noSidebar)}>
          <Header />
          <div className={classes.content}>
            {children}
          </div>
        </main>
      </div>
    </div>
  );
};

export default Layout;
