import * as React from 'react';
import Layout from '../components/Layout';
import Sidebar from '../components/Sidebar';
import PrivateRoute from '../components/PrivateRoute';
import WorkspaceInfo from '../components/WorkspaceInfo';

const WorkspaceScene: React.FC = () => (
  <PrivateRoute>
    <Layout sidebar={<Sidebar />}>
      <WorkspaceInfo />
    </Layout>
  </PrivateRoute>
);

export default WorkspaceScene;
