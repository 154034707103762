import React from 'react';
import { AuthContext } from '../providers/AuthProvider';
import { useLicenseContext } from '../providers/LicenseContextProvider';
import { WorkspaceContext } from '../providers/WorkspaceProvider';
import { WS_LIFETIME_PLAN_ID, PADDLE_LIFETIME_ID } from '../../api/paddle';
import {
  checkIfCouponIsValid, getWorkspaceUser, updateSubscriptionInDb, deactivateCoupon,
} from '../../api/subscription';
import {
  WorkspaceUserRole, provisionWorkspace, subscribePersonal, subscribeWorkspace,
} from '../../api/workspaces';
import { MessagesContext } from '../providers/MessagesProvider';

const usePlanCard = (props) => {
  const { user } = React.useContext(AuthContext);
  const { wsSubscription, subscription, fetchData } = useLicenseContext();
  const { id: workspaceId } = React.useContext(WorkspaceContext);
  const { addSuccessMsg } = React.useContext(MessagesContext);

  const {
    prices,
    teamPlan,
    lifetimePlanId,
    yearlyPlanId,
    monthlyPlanId,
    excludePlans,
  } = props;

  const initialSubscriptionType = excludePlans.includes('Monthly') ? 'Annual' : 'Monthly';
  const initialPlanId = excludePlans.includes('Monthly') ? yearlyPlanId : monthlyPlanId;
  const initialPrice = excludePlans.includes('Monthly') ? prices.yearly : prices.monthly;

  const [activationCode, setActivationCode] = React.useState('');
  const [error, setError] = React.useState('');
  const [subscriptionType, setSubscriptionType] = React.useState(initialSubscriptionType);
  const [planId, setPlanId] = React.useState(initialPlanId);
  const [price, setPrice] = React.useState(initialPrice);
  const [seats, setSeats] = React.useState(3);

  const calculatePrice = React.useCallback((type, userSeats) => {
    switch (type) {
      case 'Lifetime':
        return teamPlan ? prices.lifetime * userSeats : prices.lifetime;
      case 'Annual':
        return teamPlan ? prices.yearly * userSeats : prices.yearly;
      case 'Monthly':
        return teamPlan ? prices.monthly * userSeats : prices.monthly;
      default:
        return teamPlan ? prices.lifetime * userSeats : prices.lifetime;
    }
  }, [prices, teamPlan]);

  const handleChange = (event) => {
    const selectedType = event.target.value;
    setSubscriptionType(selectedType);
    setPrice(calculatePrice(selectedType, seats));
    switch (selectedType) {
      case 'Lifetime':
        setPlanId(lifetimePlanId);
        break;
      case 'Annual':
        setPlanId(yearlyPlanId);
        break;
      case 'Monthly':
        setPlanId(monthlyPlanId);
        break;
      default:
        setPlanId(lifetimePlanId);
    }
  };
  const sub = wsSubscription || subscription;

  const activateSeatCoupon = async (coupon: string) => {
    if (!(await checkIfCouponIsValid(coupon, setError))) {
      return;
    }

    let workspaceData = null;

    const workspaceUser = await getWorkspaceUser(user.email);
    if (workspaceUser) {
      const ws = workspaceUser.workspaces.find((w) => w.role === WorkspaceUserRole.Admin);

      if (ws) {
        workspaceData = wsSubscription;
      }
    }

    if (workspaceData && workspaceData.subscriptionPlanId === WS_LIFETIME_PLAN_ID.toString()) {
      await updateSubscriptionInDb(user.email, workspaceData.workspaceId, workspaceData.quantity + 1);
      await deactivateCoupon(coupon);
      addSuccessMsg('An activation code has been activated');
      setActivationCode('');
      fetchData();
    } else if (subscription && subscription.subscriptionPlanId === PADDLE_LIFETIME_ID.toString() && !workspaceData) {
      await provisionWorkspace(user.email);
      await deactivateCoupon(coupon);
      await subscribeWorkspace(workspaceId, user.email);
      addSuccessMsg('An activation code has been activated');
      setActivationCode('');
      fetchData();
    }
  };

  const activateLifetimeSub = async (coupon: string) => {
    if (!(await checkIfCouponIsValid(coupon, setError))) {
      return;
    }

    subscribePersonal(user.email, coupon);
    await deactivateCoupon(coupon);
    addSuccessMsg('An activation code has been activated');
    setActivationCode('');
    fetchData();
  };

  const activateCode = (coupon:string) => {
    if (sub) {
      activateSeatCoupon(coupon);
    } else {
      activateLifetimeSub(coupon);
    }
  };

  React.useEffect(() => {
    setPrice(calculatePrice(subscriptionType, seats));
  }, [calculatePrice, seats, subscriptionType]);

  return {
    activationCode,
    setActivationCode,
    error,
    setError,
    subscriptionType,
    handleChange,
    planId,
    price,
    seats,
    setSeats,
    activateCode,
    sub,
  };
};

export default usePlanCard;
