import {
  collection, doc, getDoc, getDocs, query, setDoc, where,
} from 'firebase/firestore';
import { db } from '../firebaseApp';

export enum SubscriptionStatus {
  active = 'active', // Indicates an active subscription, payments are up-to-date
  past_due = 'past_due', // Indicates we have tried a payment for this user and it has failed
  deleted = 'deleted', // Indicates that this subscription has been cancelled
}

export interface ISubscriptionBase {
  subscriptionId: string;
  subscriptionPlanId: string;
  subscriptionEndDate: string;
  subscriptionStatus: SubscriptionStatus;
  subscriptionUpdateUrl: string;
  subscriptionCancelUrl: string;
}

export interface ISubscriptionUser extends ISubscriptionBase {
  userId: string;
  email: string;
}

export interface IWorkspaceSubscription extends ISubscriptionBase {
  workspaceId: string;
  email: string;
  quantity: number;
}

export const TRIAL_PEDIOD = 7;

export interface ITrialInfo {
  trialStart: number; // timestamp
}

export const getSubscriptionInfo = async (email: string): Promise<ISubscriptionUser> => {
  const docRef = doc(db, 'subscriptions', email);

  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data() as ISubscriptionUser;
  }

  return null;
};

export const getWorkspaceUser = async (email: string) => {
  const workspaceUserRef = doc(db, 'workspaceUsers', email);

  const workspaceUser = await getDoc(workspaceUserRef);

  if (workspaceUser.exists()) {
    return workspaceUser.data();
  }
  return null;
};

export const checkIfCouponIsValid = async (
  coupon: string,
  setError: (error: string) => void,
): Promise<boolean> => {
  const subscriptionRef = collection(db, 'subscriptions');
  const subscriptionWithCouponQuery = query(subscriptionRef, where('coupon', '==', coupon));
  const subscriptionSnapshot = await getDocs(subscriptionWithCouponQuery);

  const subExists = subscriptionSnapshot.size > 0;

  if (subExists) {
    setError('This code has already been used. Please try again');
    return false;
  }

  const couponRef = doc(db, 'activation_coupons', coupon);
  const couponDoc = await getDoc(couponRef);

  const d = couponDoc.data();

  if (!d) {
    setError('Incorrect activation code. Please try again');
    return false;
  }

  if (d.activated) {
    setError('This code has already been used. Please try again');
    return false;
  }

  return !d.activated;
};

export const deactivateCoupon = async (coupon: string): Promise<void> => {
  const couponRef = doc(db, 'activation_coupons', coupon);

  await setDoc(couponRef, { activated: true }, { merge: true });
};

export const updateSubscriptionInDb = async (email, id, quantity): Promise<void> => {
  const subscriptionRef = doc(db, 'workspaceSubscriptions', id);

  await setDoc(subscriptionRef, { email, quantity }, { merge: true });
};

export const createSubscriptionInDb = async (data, id): Promise<void> => {
  const subscriptionRef = doc(db, 'workspaceSubscriptions', id);

  await setDoc(subscriptionRef, data, { merge: true });
};

export const createPersonalSubscriptionInDb = async (data, id): Promise<void> => {
  const subscriptionRef = doc(db, 'subscriptions', id);

  await setDoc(subscriptionRef, data, { merge: true });
};

export const getWorkspaceSubscriptionInfo = async (
  workspaceId: string,
): Promise<IWorkspaceSubscription> => {
  const docRef = doc(db, 'workspaceSubscriptions', workspaceId);

  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    const d = docSnap.data();
    return {
      ...d,
      quantity: parseInt(d.quantity, 10),
    } as IWorkspaceSubscription;
  }

  return null;
};

export const getTrialInfo = async (email: string): Promise<ITrialInfo> => {
  const docRef = doc(db, 'trials', email);

  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data() as ITrialInfo;
  }

  return null;
};
