import * as React from 'react';

import {
  Box,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { LANGUAGE_OPTIONS } from './language';
import { OptionsContext } from '../../providers/OptionsProvider';

export const LocalizationTab: React.FC = () => {
  const { changeOption, options } = React.useContext(OptionsContext);

  const handleChangeLanguage = (event) => {
    changeOption(event.target.value, 'language');
  };

  return (
    <Box>
      <Typography mb={4}>
        Select the language for generating step titles
      </Typography>
      <InputLabel sx={{ fontWeight: 500, marginBottom: '4px' }} id="lang-label">Language</InputLabel>
      <Select
        labelId="lang-label"
        value={options.language}
        sx={{ width: '250px', borderRadius: 2, marginBottom: 3 }}
        onChange={handleChangeLanguage}
      >
        {LANGUAGE_OPTIONS.map(({ value, label }) => (
          <MenuItem key={value} value={value}>{label}</MenuItem>
        ))}
      </Select>
      <Typography>
        If you spot any localization issues, please report here:
      </Typography>
      <Link
        target="__blank"
        href="https://docs.google.com/document/u/2/d/e/2PACX-1vTMlLaDGj9cnWWvR1gA4_2JGjaUgDl8P8OI8COak9ErW8ODkmuM-gv3ldEKZQcKWhCMU-jk64niixsV/pub"
      >
        contact@stepsy.co
      </Link>
    </Box>
  );
};

export default LocalizationTab;
