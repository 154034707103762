/* eslint-disable max-len */
import React, {
  createContext, useContext, useEffect, useState,
} from 'react';
import moment from 'moment';
import {
  getSubscriptionInfo,
  getTrialInfo,
  getWorkspaceSubscriptionInfo,
  ISubscriptionUser,
  IWorkspaceSubscription,
  TRIAL_PEDIOD,
} from '../../api/subscription';
import { AuthContext } from './AuthProvider';
import { dateDiffInDays, isLifetime } from '../../common/utils';
import { WorkspaceContext } from './WorkspaceProvider';

interface ILicenseContext {
  email: string;
  subscription: ISubscriptionUser;
  wsSubscription: IWorkspaceSubscription;
  isPro: boolean;
  isLoading: boolean;
  trialDaysLeft: number;
  subscriptionDaysLeft: number;
  fetchData: () => Promise<void>;
}

const LicenseContext = createContext<ILicenseContext>({
  email: null,
  isLoading: false,
  isPro: false,
  subscription: null,
  wsSubscription: null,
  subscriptionDaysLeft: 0,
  trialDaysLeft: 0,
  fetchData: () => Promise.resolve(),
});

export const LicenseProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [subscription, setSubscription] = useState<ISubscriptionUser>(null);
  const [wsSubscription, setWorkspaceSubscription] = useState<IWorkspaceSubscription>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [trialDaysLeft, setTrialDaysLeft] = useState(0);
  const [subscriptionDaysLeft, setSubscriptionDaysLeft] = useState(0);
  const [isPro, setIsPro] = useState(false);

  const { user } = useContext(AuthContext);

  const { id: workspaceId } = useContext(WorkspaceContext);

  const email = user?.email;

  const fetchData = React.useCallback(async () => {
    setIsLoading(true);
    try {
      // Fetch subscription and trial info
      const personalSubscriptionData = await getSubscriptionInfo(email);
      const workspaceSubscriptionData = workspaceId ? await getWorkspaceSubscriptionInfo(workspaceId) : null;
      const trialInfo = await getTrialInfo(email);

      if (workspaceSubscriptionData) {
        setWorkspaceSubscription(workspaceSubscriptionData);
      } else if (personalSubscriptionData) {
        setSubscription(personalSubscriptionData);
      }

      let trialLeft = 0;
      let subLeft = 0;

      if (trialInfo) {
        const trialEndDate = moment(trialInfo.trialStart)
          .add({ days: TRIAL_PEDIOD })
          .toDate();

        trialLeft = dateDiffInDays(trialEndDate, new Date());

        if (trialLeft > 0) {
          setTrialDaysLeft(trialLeft);
        }
      }

      const subscriptionData = workspaceSubscriptionData || personalSubscriptionData;

      // Calculate remaining days of the active subscription (if applicable)
      if (subscriptionData) {
        const subscriptionEndDate = moment(subscriptionData.subscriptionEndDate);
        const currentDate = moment();
        const remainingSubscriptionDays = subscriptionEndDate.diff(currentDate, 'days');

        subLeft = isLifetime(subscriptionData as ISubscriptionUser) ? Infinity : remainingSubscriptionDays;
      }

      setSubscriptionDaysLeft(subLeft);
      setIsPro(subLeft > 0 || trialLeft > 0);

      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching license data:', error);
    }
  }, [email, workspaceId]);

  useEffect(() => {
    if (!email) return;

    setWorkspaceSubscription(null);

    fetchData();
  }, [email, workspaceId, fetchData]);

  const value = React.useMemo(() => ({
    email,
    subscription,
    wsSubscription,
    isPro,
    isLoading,
    trialDaysLeft,
    subscriptionDaysLeft,
    fetchData,
  }), [email,
    subscription,
    wsSubscription,
    isPro,
    isLoading,
    trialDaysLeft,
    subscriptionDaysLeft,
    fetchData]);

  return (
    <LicenseContext.Provider value={value}>
      {children}
    </LicenseContext.Provider>
  );
};

export const useLicenseContext = (): ILicenseContext => {
  const context = useContext(LicenseContext);
  if (!context) {
    throw new Error('useLicenseContext must be used within a LicenseProvider');
  }
  return context;
};
