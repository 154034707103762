import { createTheme } from '@mui/material';

// eslint-disable-next-line import/prefer-default-export
export const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          fontWeight: 600,
          textTransform: 'unset',
          borderRadius: '8px',

          ...(ownerState.variant === 'contained'
                        && ownerState.color === 'primary' && {
            backgroundColor: '#3C39C5',
            color: '#FFFFFF',
            '&:hover': {
              backgroundColor: '#566FC0',
            },
          }),
          ...(ownerState.variant === 'outlined' && {
            color: '#3C39C5',
            border: '1px solid #3C39C5',
          }),
        }),
      },

    },
    MuiListItemText: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.color === 'white' && {
            color: '#FFFFFF',
            fontSize: '14px',
          }),
        }),
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: () => ({
          minWidth: 'unset',
          marginRight: '8px',
        }),
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.orientation === 'vertical' && {
            height: '16px',
            width: 'auto',
          }),
        }),
      },
    },
    MuiTab: {
      styleOverrides: {
        root: () => ({
          textTransform: 'unset',
        }),
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: () => ({
          fontWeight: 700,
          fontSize: '24px',
          padding: '48px 32px 0 32px',
        }),
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: () => ({
          padding: '16px 32px',
        }),
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: () => ({
          padding: '32px',
          justifyContent: 'space-between',
        }),
      },
    },
    MuiChip: {
      styleOverrides: {
        root: () => ({
          height: '24px',
          padding: '0 16px',
        }),
        label: {
          padding: 0,
          fontWeight: '500',
          fontSize: '12px',
        },
      },
    },
  },
  typography: {
    fontFamily: 'Poppins, sans-serif',
  },
  palette: {
    primary: {
      main: '#3C39C5',
      dark: '#333333',
    },
    secondary: {
      main: '#D9D9D9',
    },
    grey: {
      50: '#828282',
    },
    background: {
      default: '#F1F1F1',
    },
  },
});
