import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  IOptions,
  getWorkspaceOptions,
} from '../../api/workspaces';
import { WorkspaceContext } from '../providers/WorkspaceProvider';

export const useWorkspaceOptions = () => {
  const [workspaceOptions, setWorkspaceOptions] = useState<IOptions>(null);
  const [loading, setLoading] = useState(true);
  const { id: workspaceId } = React.useContext(WorkspaceContext);

  const fetchWorkspaceOptions = React.useCallback(async (id: string) => {
    if (!id) return;
    setLoading(true);
    const data = await getWorkspaceOptions(id);

    setWorkspaceOptions(data);
    setLoading(false);
  }, [setLoading, setWorkspaceOptions]);

  useEffect(() => {
    fetchWorkspaceOptions(workspaceId);
  }, [fetchWorkspaceOptions, workspaceId]);

  return {
    workspaceOptions,
    loading,
    fetchWorkspaceOptions,
  };
};

export default useWorkspaceOptions;
