import * as React from 'react';

import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../providers/AuthProvider';

export const PrivateRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { user, authLoaded } = React.useContext(AuthContext);

  const navigate = useNavigate();

  React.useEffect(() => {
    if (!authLoaded) return;

    if (!user) {
      navigate('/login');
    }
  }, [user, authLoaded, navigate]);

  return (
    <div>
      {authLoaded && user ? children : null}
    </div>
  );
};

export default PrivateRoute;
