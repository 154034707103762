import React, {
  createContext, useEffect, useState,
} from 'react';
import {
  GoogleAuthProvider, signInWithCredential, signInWithPopup,
} from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../firebaseApp';

export interface IAuthUser {
  email: string;
  photoURL: string;
  displayName: string;
  uid: string;
  accessToken: string;
}

export interface AuthContextType {
  user: IAuthUser | null;
  authLoaded: boolean;
  signInWithGoogle: () => Promise<void>;
  signOut: () => Promise<void>;
  signInWithToken: (token: string) => Promise<void>;
}

export const AuthContext = createContext<AuthContextType>({
  user: null,
  authLoaded: false,
  signInWithGoogle: async () => {},
  signOut: async () => {},
  signInWithToken: async () => {},
});

export const AuthProvider: React.FC<{ children: any }> = ({ children }) => {
  const [user, setUser] = useState<IAuthUser | null>(null);
  const [authLoaded, setLoaded] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((u) => {
      setUser(u as any as IAuthUser);
      setLoaded(true);
    });

    return unsubscribe;
  }, []);

  const signInWithGoogle = React.useCallback(async () => {
    const googleProvider = new GoogleAuthProvider();
    await signInWithPopup(auth, googleProvider);

    navigate('/get-started');
  }, [navigate]);

  const signOut = React.useCallback(async () => {
    await auth.signOut();
    navigate('/login');
  }, [navigate]);

  const signInWithToken = async (googleToken) => {
    const credential = GoogleAuthProvider.credential(null, googleToken);

    await signInWithCredential(auth, credential);
  };

  const contextValue: AuthContextType = React.useMemo(() => ({
    user,
    authLoaded,
    signInWithGoogle,
    signOut,
    signInWithToken,
  }), [user, authLoaded, signInWithGoogle, signOut]);

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};
