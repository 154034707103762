import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

import { makeStyles } from '@mui/styles';
import {
  Avatar, Box, Button, Menu, MenuItem,
} from '@mui/material';
import { AuthContext } from '../../providers/AuthProvider';
import { getAvatar } from './getAvatar';
import arrow from '../../../assets/icons/arrow.svg';
import UserStatus from './userStatus';

const useStyles = makeStyles(() => ({
  root: {
    background: '#fff !important',
    boxShadow: '0px 4px 8px rgba(46, 49, 51, 0.1) !important',
    paddingRight: '16px',
  },

  toolbar: {
    justifyContent: 'end',
  },

  logo: {
    display: 'flex',
    alignItems: 'center',
    color: '#000',
    textDecoration: 'none',
  },

  logoImage: {
    width: '48px',
  },

  logoName: {
    display: 'block',
    marginLeft: '8px',
    fontSize: '18px',
  },

  email: {
    fontSize: '14px',
  },
}));

const Header: React.FC = () => {
  const classes = useStyles();

  const { user, signOut } = React.useContext(AuthContext);

  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const onSignOut = () => {
    handleCloseUserMenu();
    signOut();
  };

  return (
    <AppBar position="static" color="default" className={classes.root}>
      <Toolbar className={classes.toolbar}>
        { user
          ? (
            <Box display="flex" alignItems="center">
              <UserStatus />
              <Button
                sx={{
                  display: 'flex', alignItems: 'center', gap: '8px', color: 'rgba(51, 51, 51, 1)',
                }}
                onClick={handleOpenUserMenu}
              >
                <h3 className={classes.email}>{user.email}</h3>
                <img src={arrow} alt=">" />
                <Avatar src={getAvatar(user)} />
              </Button>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem onClick={onSignOut}>Log Out</MenuItem>
              </Menu>
            </Box>
          ) : null }
      </Toolbar>
    </AppBar>
  );
};

export default Header;
