// prod
export const PADDLE_MONTHLY_ID = 796285;
export const PADDLE_YEARLY_ID = 797938;
export const PADDLE_LIFETIME_ID = 797939;

export const STEPSY_BASIC = 'Stepsy Basic';
export const STEPSY_PRO = 'Stepsy Personal Pro';

export const MONTHLY_PRICE = 7;
export const YEARLY_PRICE = 72;
export const LIFETIME_PRICE = 119;

// workspace
export const STEPSY_WORKSPACE_PRO = 'Stepsy Workspace Pro';

export const WS_MONTHLY_SEAT_PRICE = 6;
export const WS_YEARLY_SEAT_PRICE = 56;
export const WS_LIFETIME_SEAT_PRICE = 83;

export const WS_MONTHLY_PLAN_ID = 849513; // prod
export const WS_YEARLY_PLAN_ID = 849519; // prod
export const WS_LIFETIME_PLAN_ID = 851972; // prod

export const isWorkspacePlan = (
  planId: number,
) => [WS_MONTHLY_PLAN_ID, WS_YEARLY_PLAN_ID, WS_LIFETIME_PLAN_ID].indexOf(planId) !== -1;
