import * as React from 'react';

import { makeStyles } from '@mui/styles';
import {
  Box,
  Button,
  Link,
  Typography,
} from '@mui/material';
import Layout from '../components/Layout';
import Sidebar from '../components/Sidebar';
import { WorkspaceContext } from '../providers/WorkspaceProvider';
import { OptionsContext } from '../providers/OptionsProvider';

const useStyles = makeStyles(() => ({
  root: {
  },
}));

export const GetStartedScene: React.FC = () => {
  const classes = useStyles();
  const { id: workspaceId, loading } = React.useContext(WorkspaceContext);
  const {
    addDefaultOptions,
  } = React.useContext(OptionsContext);

  React.useEffect(() => {
    addDefaultOptions();
  }, [addDefaultOptions, loading, workspaceId]);

  return (
    <Layout sidebar={<Sidebar />}>
      <Box className={classes.root}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography fontSize={24} fontWeight={600}>
            Get started
          </Typography>

          <Button variant="outlined">
            <Link
              sx={{ textDecoration: 'unset' }}
              target="__blank"
              href="https://docs.google.com/document/u/2/d/e/2PACX-1vTMlLaDGj9cnWWvR1gA4_2JGjaUgDl8P8OI8COak9ErW8ODkmuM-gv3ldEKZQcKWhCMU-jk64niixsV/pub"
            >
              Quick Start Quide
            </Link>
          </Button>
        </Box>

        <br />

        <Typography fontSize={18} fontWeight={600}>
          What can you do with Stepsy?
        </Typography>

        <ul>
          <li>Train employees</li>
          <li>Share knowledge</li>
          <li>Delegate tasks</li>
          <li>Create knowledge base articles</li>
          <li>Deliver documentation for clients</li>
          <li>Document business processes</li>
        </ul>
      </Box>
    </Layout>
  );
};

export default GetStartedScene;
