import * as React from 'react';

import {
  Box,
  FormControlLabel,
  Paper,
  Switch,
  Typography,
} from '@mui/material';
// eslint-disable-next-line import/no-extraneous-dependencies
import TwitterPicker from 'react-color/lib/components/twitter/Twitter';
import { makeStyles } from '@mui/styles';
import screen from '../../../assets/img/screenshot.png';
import { OptionsContext } from '../../providers/OptionsProvider';

const useStyles = makeStyles(() => ({
  textStyle: {
    width: '500px',
    marginBottom: '32px !important',
    '@media (max-width:880px)': {
      width: 'unset',
    },
  },
  contentBox: {
    display: 'flex',
    gap: '32px',
    '@media (max-width:1100px)': {
      flexDirection: 'column',
    },
  },
  colorBlock: {
    marginLeft: '32px',
    '@media (max-width:1100px)': {
      marginLeft: 0,
    },
  },
}));

export const BrandingTab: React.FC = () => {
  const { changeOption, options } = React.useContext(OptionsContext);
  const classes = useStyles();

  const handleChangeEnableHighlight = (event) => {
    changeOption(event.target.checked, 'highlightOn');
  };

  const handleChangePagelessMode = (event) => {
    changeOption(event.target.checked, 'pagelessMode');
  };

  const handleChangeColor = (v) => {
    changeOption(v.hex, 'color');
  };

  return (
    <Box>
      <Typography className={classes.textStyle}>
        Change the color of captured element highlight and merged step sequence
        circle
      </Typography>
      <Box className={classes.contentBox}>
        <Paper variant="elevation" sx={{ position: 'relative' }}>
          <Box
            sx={{
              position: 'absolute',
              border: `1px solid ${options.color}`,
              width: 99,
              height: 20,
              borderRadius: '2px',
              left: '107px',
              top: '71px',
            }}
          />
          <img src={screen} width={400} alt="screen" />
        </Paper>
        <Box className={classes.colorBlock}>
          <FormControlLabel
            control={(
              <Switch
                checked={options.highlightOn}
                onChange={handleChangeEnableHighlight}
              />
            )}
            label="Enable click highlight"
          />
          <Typography variant="body1" marginBottom="8px" marginTop="16px">
            Click to select highlight color
          </Typography>

          <Box
            sx={{
              opacity: !options.highlightOn ? 0.5 : 1,
              pointerEvents: !options.highlightOn ? 'none' : 'all',
            }}
          >
            <TwitterPicker
              colors={[
                '#5172ff',
                '#EB144C',
                '#FF6900',
                '#FCB900',
                '#7BDCB5',
                '#00D084',
                '#0693E3',
              ]}
              onChange={handleChangeColor}
              color={options.color}
            />
          </Box>
        </Box>
      </Box>
      <FormControlLabel
        control={(
          <Switch
            checked={options.pagelessMode}
            onChange={handleChangePagelessMode}
          />
        )}
        label="Export in pageless format"
        sx={{ mt: '22px' }}
      />
    </Box>
  );
};

export default BrandingTab;
