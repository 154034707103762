import React from 'react';
import {
  Box,
  Chip,
} from '@mui/material';
import { useLicenseContext } from '../../providers/LicenseContextProvider';
import { dateDiffInDays, isLifetime } from '../../../common/utils';

const UserStatus: React.FC = () => {
  const {
    subscription, trialDaysLeft, wsSubscription, isLoading,
  } = useLicenseContext();

  const daysLeft = dateDiffInDays(
    new Date(subscription?.subscriptionEndDate),
    new Date(),
  );

  let chipLabel = 'Free';

  const sub = wsSubscription || subscription;

  if (sub) {
    if (daysLeft > 0 || isLifetime(sub)) {
      chipLabel = 'Activated';
    }
  } else if (trialDaysLeft > 0) {
    chipLabel = 'Trial';
  }
  return (
    <Box>
      {!isLoading
        && (
        <Chip
          size="small"
          variant="filled"
          // eslint-disable-next-line no-nested-ternary
          style={chipLabel === 'Activated'
            ? { backgroundColor: 'rgba(33, 150, 83, 1)', color: '#fff' }
            : chipLabel === 'Free'
              ? { backgroundColor: 'rgba(47, 128, 237, 1)', color: '#fff' }
              : { backgroundColor: 'rgba(240, 216, 0, 1)', color: 'rgba(73, 73, 73, 1)' }}
          label={chipLabel}
          clickable
        />
        )}
    </Box>

  );
};

export default UserStatus;
