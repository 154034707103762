import moment from 'moment';
import { ISubscriptionUser } from '../api/subscription';
import { PADDLE_LIFETIME_ID, WS_LIFETIME_PLAN_ID } from '../api/paddle';

// eslint-disable-next-line max-len
export const dateDiffInDays = (endDate: Date, startDate: Date): number => Math.round(moment.duration(moment(endDate).diff(startDate)).asDays());

// eslint-disable-next-line max-len
export const isLifetime = (subscription: Pick<ISubscriptionUser, 'subscriptionPlanId'>) => !!subscription
&& (
  subscription.subscriptionPlanId === PADDLE_LIFETIME_ID.toString()
    || subscription.subscriptionPlanId === WS_LIFETIME_PLAN_ID.toString()
);
