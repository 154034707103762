// eslint-disable-next-line import/prefer-default-export
export const LANGUAGE_OPTIONS = [
  {
    label: 'English',
    value: 'en',
  },
  {
    label: 'Українська',
    value: 'ua',
  },
  {
    label: '中文（简体）',
    value: 'zh_CN',
  },
  {
    label: 'Čeština',
    value: 'cs_CZ',
  },
  {
    label: 'Български',
    value: 'bg_BG',
  },
  {
    label: 'Dansk',
    value: 'da_DK',
  },
  {
    label: '中文（繁體）',
    value: 'zh_TW',
  },
  {
    label: 'Nederlands',
    value: 'nl_NL',
  },
  {
    label: 'Suomi',
    value: 'fi_FI',
  },
  {
    label: 'Deutsch',
    value: 'de_DE',
  },
  {
    label: 'Français',
    value: 'fr_FR',
  },
  {
    label: 'Ελληνικά',
    value: 'el_GR',
  },
  {
    label: 'हिन्दी',
    value: 'hi_IN',
  },
  {
    label: 'Magyar',
    value: 'hu_HU',
  },
  {
    label: 'Italiano',
    value: 'it_IT',
  },
  {
    label: 'Indonesian',
    value: 'id_ID',
  },
  {
    label: '日本語',
    value: 'ja_JP',
  },
  {
    label: '한국어',
    value: 'ko_KR',
  },
  {
    label: 'Latviešu',
    value: 'lv_LV',
  },
  {
    label: 'Lietuvių',
    value: 'lt_LT',
  },
  {
    label: 'Norsk',
    value: 'nb_NO',
  },
  {
    label: 'Polski',
    value: 'pl_PL',
  },
  {
    label: 'Português (Europe)',
    value: 'pt_PT',
  },
  {
    label: 'Română',
    value: 'ro_RO',
  },
  {
    label: 'Español (España)',
    value: 'es_ES',
  },
  {
    label: 'Српски',
    value: 'sr_RS',
  },
  {
    label: 'Svenska',
    value: 'sv_SE',
  },
  {
    label: 'Türkçe',
    value: 'tr_TR',
  },
  {
    label: 'Tiếng Việt',
    value: 'vi_VN',
  },
  {
    label: 'Eesti',
    value: 'et_EE',
  },
];
