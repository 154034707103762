import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Select, SelectChangeEvent } from '@mui/material';

interface ISubscriptionTypeSelect {
  subscriptionType: string;
  handleChange: (event: SelectChangeEvent<string>) => void;
  allOptionsDisabled: boolean;
  excludePlans: string[];
}

export const SubscriptionTypeSelect: React.FC<ISubscriptionTypeSelect> = ({
  subscriptionType,
  handleChange,
  allOptionsDisabled,
  excludePlans,
}) => (
  <FormControl sx={{ width: '100%', mb: '24px' }}>
    <Select
      value={subscriptionType}
      onChange={handleChange}
      disabled={allOptionsDisabled}
    >
      {!excludePlans.includes('Lifetime') && <MenuItem value="Lifetime">Lifetime</MenuItem>}
      {!excludePlans.includes('Annual') && <MenuItem value="Annual">Annual</MenuItem>}
      {!excludePlans.includes('Monthly') && <MenuItem value="Monthly">Monthly</MenuItem>}
    </Select>
  </FormControl>
);

export default SubscriptionTypeSelect;
