import {
  doc, getDoc, setDoc, collection, updateDoc,
} from 'firebase/firestore';
import { v4 } from 'uuid';
import { SubscriptionStatus, createPersonalSubscriptionInDb, createSubscriptionInDb } from './subscription';

import { db } from '../firebaseApp';
import { PADDLE_LIFETIME_ID, WS_LIFETIME_PLAN_ID } from './paddle';

export interface IWorkspace {
  id: string;
  name: string;
  team: string[];
}

export enum WorkspaceUserRole {
  Admin = 0,
  User = 1,
}

export interface IOptions {
  color: string,
  language: string,
  mergeSize: number,
  cropWidth: number,
  cropHeight: number,
  smartCrop: boolean,
  highlightOn:boolean,
  numberingOn:boolean,
  pagelessMode: boolean,
}

export interface IWorkspaceUserRecord {
  workspaceId: string;
  role: WorkspaceUserRole;
}

export interface IWorkspaceUser {
  email: string;
  workspaces: IWorkspaceUserRecord[];
}

export interface IWorkspaceSubscription {
  workspaceId: string;
  email: string;
  subscriptionId: string;
  subscriptionPlanId: string;
  subscriptionEndDate: string;
  subscriptionStatus: SubscriptionStatus;
  subscriptionUpdateUrl: string;
  subscriptionCancelUrl: string;
  seats: number;
}

export const WORKSPACE_OPTIONS_DEFAULT = {
  color: '#5172FF',
  language: 'en',
  mergeSize: 5,
  cropWidth: 600,
  cropHeight: 400,
  highlightOn: true,
  smartCrop: true,
  numberingOn: true,
  pagelessMode: true,
};

export const getWorkspaceOptions = async (id: string): Promise<IOptions | null> => {
  const workspaceOptionsRef = doc(db, 'workspaceOptions', id);

  const workspaceOptionsSnap = await getDoc(workspaceOptionsRef);

  if (workspaceOptionsSnap.exists()) {
    return workspaceOptionsSnap.data() as IOptions;
  }
  return null;
};

export const subscribeWorkspace = async (workspaceId, email) => {
  const subData = {
    email,
    workspaceId,
    subscriptionPlanId: WS_LIFETIME_PLAN_ID.toString(),
    quantity: 2,
    subscriptionStatus: SubscriptionStatus.active,
    coupon: null,
    subscriptionCancelUrl: null,
    subscriptionEndDate: null,
    subscriptionUpdateUrl: null,
  };

  await createSubscriptionInDb(subData, workspaceId);
};

export const subscribePersonal = async (email, coupon) => {
  const subData = {
    coupon,
    email,
    subscriptionPlanId: PADDLE_LIFETIME_ID.toString(),
    subscriptionStatus: SubscriptionStatus.active,
    userId: email,
  };

  await createPersonalSubscriptionInDb(subData, email);
};

export const provisionWorkspace = async (email: string): Promise<IWorkspace> => {
  const workspaceId = v4();

  const workspacesRef = collection(db, 'workspaces');

  await setDoc(doc(workspacesRef, workspaceId), {
    id: workspaceId,
    name: 'My Workspace',
    team: [email],
  } as IWorkspace);

  const workspaceOptionsRef = collection(db, 'workspaceOptions');

  await setDoc(doc(workspaceOptionsRef, workspaceId), {
    ...WORKSPACE_OPTIONS_DEFAULT,
  } as IOptions);

  const workspaceUsersRef = collection(db, 'workspaceUsers');

  await setDoc(doc(workspaceUsersRef, email), {
    email,
    workspaces: [{
      workspaceId,
      role: WorkspaceUserRole.Admin,
    }],
  } as IWorkspaceUser);

  const workspaceRef = doc(db, 'workspaces', workspaceId);

  const docSnap = await getDoc(workspaceRef);

  return docSnap.data() as IWorkspace;
};

export interface IWorkspaceMember {
  email: string;
  role: WorkspaceUserRole;
}

export interface IFullWorkspaceData {
  workspace: IWorkspace;
  workspaceTeam: IWorkspaceMember[];
  role: WorkspaceUserRole;
  email: string;
}

export const getWorkspaceById = async (workspaceId: string): Promise<IWorkspace> => {
  const workspaceRef = doc(db, 'workspaces', workspaceId);

  const workspaceSnap = await getDoc(workspaceRef);

  return workspaceSnap.data() as IWorkspace;
};

export const getUserWorkspaces = async (email: string): Promise<IWorkspace[]> => {
  const workspaceUserRef = doc(db, 'workspaceUsers', email);

  let workspaceUserSnap = await getDoc(workspaceUserRef);

  if (!workspaceUserSnap.exists()) {
    await provisionWorkspace(email);
    workspaceUserSnap = await getDoc(workspaceUserRef);
  }

  const workspaceUser = workspaceUserSnap.data() as IWorkspaceUser;

  const ids = workspaceUser.workspaces.map((w) => w.workspaceId);

  const workspaces: IWorkspace[] = [];

  const putWorkspace = async (id: string) => {
    const data = await getWorkspaceById(id);

    workspaces.push(data);
  };

  const promises = ids.map((id) => putWorkspace(id));

  await Promise.all(promises);

  return workspaces;
};

export const getFullWorkspaceData = async (
  email: string,
  workspaceId: string,
): Promise<IFullWorkspaceData> => {
  const workspace = await getWorkspaceById(workspaceId);

  const team: IWorkspaceMember[] = [];

  const putIntoTeam = async (userEmail: string) => {
    const userRef = doc(db, 'workspaceUsers', userEmail);

    const userSnap = await getDoc(userRef);
    const userData = userSnap.data() as IWorkspaceUser;

    const workspaceInfo = userData?.workspaces.find((w) => w.workspaceId === workspaceId);

    if (workspaceInfo) {
      team.push({
        email: userData?.email,
        role: workspaceInfo?.role,
      });
    }
  };
  const fetchTeamPromises = [];

  for (const userEmail of workspace.team) {
    fetchTeamPromises.push(putIntoTeam(userEmail));
  }

  await Promise.all(fetchTeamPromises);

  const workspaceUser = team.find((u) => u.email === email);

  return {
    workspace,
    workspaceTeam: team,
    role: workspaceUser?.role,
    email: workspaceUser?.email,
  };
};

export const updateWorkspaceName = async (workspaceId: string, newName: string): Promise<void> => {
  const workspaceRef = doc(db, 'workspaces', workspaceId);

  await setDoc(workspaceRef, { name: newName }, { merge: true });
};

export const updateWorkspaceOption = async (
  workspaceId: string,
  optionName: string,
  newValue: string | boolean | number,
): Promise<void> => {
  const workspaceOptionRef = doc(db, 'workspaceOptions', workspaceId);

  const dynamicOptions = { [optionName]: newValue };

  await setDoc(workspaceOptionRef, dynamicOptions, { merge: true });
};

export const setDefaultWorkspaceOptions = async (workspaceId: string): Promise<void> => {
  const workspaceOptionsRef = collection(db, 'workspaceOptions');

  await setDoc(doc(workspaceOptionsRef, workspaceId), {
    ...WORKSPACE_OPTIONS_DEFAULT,
  } as IOptions);
};

export const addUserToWorkspace = async (
  workspaceId: string,
  userEmail: string,
  role: WorkspaceUserRole = WorkspaceUserRole.User,
): Promise<void> => {
  const workspaceRef = doc(db, 'workspaces', workspaceId);
  const workspaceSnapshot = await getDoc(workspaceRef);
  const workspaceData = workspaceSnapshot.data() as IWorkspace;

  await setDoc(workspaceRef, {
    team: [...workspaceData.team, userEmail],
  }, { merge: true });

  const workspaceUserRef = doc(db, 'workspaceUsers', userEmail);
  const workspaceUserSnapshot = await getDoc(workspaceUserRef);

  if (workspaceUserSnapshot.exists()) {
    const userData = workspaceUserSnapshot.data() as { workspaces: IWorkspaceUserRecord[] };
    userData.workspaces.push({ workspaceId, role });
    await setDoc(workspaceUserRef, { workspaces: userData.workspaces }, { merge: true });
  } else {
    await setDoc(workspaceUserRef, {
      email: userEmail,
      workspaces: [{ workspaceId, role }],
    });
  }
};

export const removeUserFromWorkspace = async (
  workspaceId: string,
  userEmail: string,
): Promise<void> => {
  const workspaceRef = doc(db, 'workspaces', workspaceId);

  const workspaceSnapshot = await getDoc(workspaceRef);
  const workspaceData = workspaceSnapshot.data() as IWorkspace;

  await setDoc(workspaceRef, {
    team: workspaceData.team.filter((v) => v !== userEmail),
  }, { merge: true });

  const workspaceUserRef = doc(db, 'workspaceUsers', userEmail);
  const workspaceUserSnapshot = await getDoc(workspaceUserRef);
  const workspaceUserData = workspaceUserSnapshot.data() as IWorkspaceUser;

  if (workspaceUserSnapshot.exists()) {
    const updatedWorkspaces = workspaceUserData.workspaces.filter((workspace) => workspace.workspaceId !== workspaceId);
    await updateDoc(workspaceUserRef, { workspaces: updatedWorkspaces });
  }
};
