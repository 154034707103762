import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { HashRouter, Route, Routes } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material';
import LoginScene from './ui/scenes/LoginScene';

import './firebaseApp';
import { AuthProvider } from './ui/providers/AuthProvider';
import WorkspaceScene from './ui/scenes/WorkspaceScene';
import NotFoundScene from './ui/scenes/NotFoundScene';
import DefaultScene from './ui/scenes/DefaultScene';
import SubscriptionScene from './ui/scenes/SubscriptionScene';
import TeamScene from './ui/scenes/TeamScene';
import WorkspaceProvider from './ui/providers/WorkspaceProvider';
import { LicenseProvider } from './ui/providers/LicenseContextProvider';
import GetStartedScene from './ui/scenes/GetStartedScene';
import MessagesProvider from './ui/providers/MessagesProvider';
import { theme } from './ui/theme';
import OptionsProvider from './ui/providers/OptionsProvider';

ReactDOM.render(
  <React.StrictMode>
    <CssBaseline />
    <HashRouter>
      <AuthProvider>
        <MessagesProvider>
          <WorkspaceProvider>
            <OptionsProvider>
              <LicenseProvider>
                <ThemeProvider theme={theme}>
                  <Routes>
                    <Route path="/" element={<DefaultScene />} />
                    <Route path="/get-started" element={<GetStartedScene />} />
                    <Route path="/login" element={<LoginScene />} />
                    <Route path="/workspace" element={<WorkspaceScene />} />
                    <Route path="/subscription" element={<SubscriptionScene />} />
                    <Route path="/team" element={<TeamScene />} />
                    <Route path="*" element={<NotFoundScene />} />
                  </Routes>
                </ThemeProvider>
              </LicenseProvider>
            </OptionsProvider>
          </WorkspaceProvider>
        </MessagesProvider>
      </AuthProvider>
    </HashRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);
