import React from 'react';
import { Box, TextField, Typography } from '@mui/material';

interface IActivationCodeInput {
  activationCode: string;
  setActivationCode: (code: string) => void;
  error: string;
  setError: (error: string) => void;
}

export const ActivationCodeInput: React.FC<IActivationCodeInput> = ({
  activationCode, setActivationCode, error, setError,
}) => (
  <Box display="flex" alignItems="center" gap={2} mb="24px" flexDirection="column" position="relative" sx={error ? { mb: '35px' } : { mb: '24px' }}>
    <TextField
      sx={{ width: '100%' }}
      name="activationCode"
      value={activationCode}
      onChange={(e) => { setActivationCode(e.target.value.trim()); setError(''); }}
      margin="normal"
      variant="outlined"
      placeholder="Enter activation code"
      InputProps={error ? {
        inputProps: {
          style: {
            border: '1px solid rgba(235, 87, 87, 1)',
            borderRadius: '5px',
          },
        },
      } : null}
    />
    <Typography sx={{
      position: 'absolute',
      top: '75px',
      left: '16px',
      color: 'rgba(235, 87, 87, 1)',
      fontSize: '12px !important',
      fontWeight: '500 !important',
    }}
    >
      {error}
    </Typography>
  </Box>
);

export default ActivationCodeInput;
