import {
  Box, FormControlLabel, Switch, Typography,
} from '@mui/material';
import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { OptionsContext } from '../../providers/OptionsProvider';
import ValidatedNumberInput from '../common/ValidatedNumberInput';

const useStyles = makeStyles(() => ({
  textStyle: {
    width: '493px',
    marginBottom: '32px !important',
    '@media (max-width:880px)': {
      width: 'unset',
    },
  },
}));

export const StepsTab: React.FC = () => {
  const theme = useTheme();
  const classes = useStyles();
  const { changeOption, options } = React.useContext(OptionsContext);

  const handleChange = (value, key) => {
    changeOption(value, key);
  };

  return (
    <Box>
      <Typography className={classes.textStyle}>
        Your captured steps are analyzed and similar steps are merged into one
        sequence automatically
      </Typography>
      <Typography mb={1}>
        Set maximum merged group size (5 by default)
      </Typography>
      <Box display="flex" alignItems="center" gap={2} height="52px" mb={4}>
        <ValidatedNumberInput
          id="mergeSize"
          variant="outlined"
          value={options.mergeSize}
          onChange={(value) => handleChange(value, 'mergeSize')}
          type="number"
          InputProps={{
            inputProps: {
              style: {
                padding: '14.5px 4px',
                width: '62px',
                textAlign: 'right',
              },
            },
          }}
          min={1}
          max={10}
        />
        <Typography color={theme.palette.grey[50]} fontWeight={500} fontSize={14}>
          Max group size (1-10)
        </Typography>
      </Box>
      <FormControlLabel
        control={
          <Switch checked={options.numberingOn} onChange={(e) => handleChange(e.target.checked, 'numberingOn')} />
        }
        label="Enable steps numbering"
      />
      <Typography mt={3} className={classes.textStyle}>
        Auto-crop the captured screenshot around the click area.
        You can modify the scale and position of the crop in an image editor
        using Pan and Zoom tools.
      </Typography>
      <FormControlLabel
        control={
          <Switch checked={options.smartCrop} onChange={(e) => handleChange(e.target.checked, 'smartCrop')} />
        }
        label="Enable smart crop"
      />
      <Typography sx={{ margin: '16px 0' }}>
        Modify the viewport size for Smart Crop (600x400 by default)
      </Typography>
      <Box display="flex" gap={3}>
        <Box>
          <Typography mb="4px" color={theme.palette.grey[50]}>
            Width (px)
          </Typography>
          <ValidatedNumberInput
            id="cropWidth"
            variant="outlined"
            value={options.cropWidth}
            onChange={(value) => handleChange(value, 'cropWidth')}
            type="number"
            InputProps={{
              inputProps: {
                style: {
                  padding: '14.5px 4px',
                  width: '112px',
                  textAlign: 'right',
                },
              },
            }}
            min={1}
            disabled={!options.smartCrop}
          />
        </Box>
        <Box>
          <Typography mb="4px" color={theme.palette.grey[50]}>
            Height(px)
          </Typography>
          <ValidatedNumberInput
            id="cropHeight"
            variant="outlined"
            value={options.cropHeight}
            onChange={(value) => handleChange(value, 'cropHeight')}
            type="number"
            InputProps={{
              inputProps: {
                style: {
                  padding: '14.5px 4px',
                  width: '112px',
                  textAlign: 'right',
                },
              },
            }}
            min={1}
            disabled={!options.smartCrop}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default StepsTab;
