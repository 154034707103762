import * as React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import Layout from '../components/Layout';
import Sidebar from '../components/Sidebar';
import PrivateRoute from '../components/PrivateRoute';
import { WorkspacePlans } from '../components/SubscriptionPlans';
import { useLicenseContext } from '../providers/LicenseContextProvider';
import SubscriptionMessage from '../components/SubscriptionPlans/SubscriptionMessage';

const SubscriptionScene: React.FC = () => {
  const { isLoading } = useLicenseContext();

  return (
    <PrivateRoute>
      <Layout sidebar={<Sidebar />}>
        <Typography fontSize={24} fontWeight={600}>
          Subscription
        </Typography>
        <SubscriptionMessage />
        { isLoading ? <CircularProgress /> : (
          (
            <Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <WorkspacePlans />
              </Box>
            </Box>
          )

        ) }
      </Layout>
    </PrivateRoute>
  );
};

export default SubscriptionScene;
