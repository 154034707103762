import * as React from 'react';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';

interface ITransferUserModal {
  tranferDialogOpen:boolean,
  handleCloseTranferDialog: () => void,
  transferEmail: string
}

export const TransferUserModal: React.FC<ITransferUserModal> = ({
  tranferDialogOpen, handleCloseTranferDialog, transferEmail,
}) => {
  const [target, setTarget] = React.useState('');
  const [error, setError] = React.useState<string>('');
  const [isLoading, setLoading] = React.useState<boolean>(false);

  const isValidEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!isValidEmail(target)) {
      setError('Please enter a valid email address.');
      return;
    }

    if (!target) return;

    setLoading(true);
    setError('');

    axios.post(
      'https://us-central1-stepsy-6f4f5.cloudfunctions.net/subscriptions/transfer-account',
      { coupon: '1', email: transferEmail, target },
    )
      .then(() => handleCloseTranferDialog())
      .catch((e) => {
        if (e?.response?.data === 'User not found') {
          setError('User not found.');
        } else {
          setError('Something went wrong, Reach out to contact@stepsy.co for getting support');
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <Dialog
      open={tranferDialogOpen}
      onClose={handleCloseTranferDialog}
      PaperProps={{ sx: { width: 412, height: 500 } }}
    >
      <Box>
        <IconButton
          aria-label="close"
          onClick={handleCloseTranferDialog}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Box>
        <DialogTitle>Transfer account</DialogTitle>
      </Box>

      <DialogContent>
        <Box mb={2}>
          <Typography sx={{ color: 'rgba(130, 130, 130, 1)', marginBottom: '4px' }}>Activation Email</Typography>
          <TextField
            fullWidth
            value={transferEmail}
            disabled
          />
        </Box>
        <Box mb={2}>
          <Typography sx={{ color: 'rgba(130, 130, 130, 1)', marginBottom: '4px' }}>Target Email</Typography>
          <TextField
            name="target"
            label="Enter target email"
            fullWidth
            onChange={(e) => { setTarget(e.target.value); }}
          />
        </Box>
        {error && (
          <Alert severity="error">
            {error}
          </Alert>
        ) }
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseTranferDialog} color="primary">
          Cancel
        </Button>
        <Button
          onClick={(e) => { handleSubmit(e); }}
          color="primary"
          variant="contained"
          disabled={isLoading}
        >
          Transfer Account
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TransferUserModal;
