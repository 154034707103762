/* eslint-disable max-len */
import * as React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Divider } from '@mui/material';
import PlanCard from './PlanCard';
import {
  LIFETIME_PRICE,
  MONTHLY_PRICE,
  PADDLE_LIFETIME_ID,
  PADDLE_MONTHLY_ID,
  PADDLE_YEARLY_ID,
  WS_LIFETIME_PLAN_ID,
  WS_LIFETIME_SEAT_PRICE,
  WS_MONTHLY_PLAN_ID,
  WS_MONTHLY_SEAT_PRICE,
  WS_YEARLY_PLAN_ID,
  WS_YEARLY_SEAT_PRICE,
  YEARLY_PRICE,
} from '../../../api/paddle';
import { useLicenseContext } from '../../providers/LicenseContextProvider';

export interface IProps {}

const useStyles = makeStyles(() => ({
  root: {
    marginTop: 20,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginBottom: 30,
  },
  workspacePlans: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '24px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  divider: {
    '@media (max-width:1440px)': {
      display: 'none',
    },
  },
}));

export const WorkspacePlans: React.FC = () => {
  const { wsSubscription, subscription } = useLicenseContext();
  const classes = useStyles();

  const getExcludePlans = (sub) => {
    const excludePlans = [];
    let allOptionsDisabled = false;

    if (sub) {
      switch (sub.subscriptionPlanId) {
        case PADDLE_MONTHLY_ID.toString():
        case WS_MONTHLY_PLAN_ID.toString():
          excludePlans.push('Monthly');
          break;
        case PADDLE_YEARLY_ID.toString():
        case WS_YEARLY_PLAN_ID.toString():
          excludePlans.push('Monthly', 'Annual');
          break;
        case PADDLE_LIFETIME_ID.toString():
        case WS_LIFETIME_PLAN_ID.toString():
          allOptionsDisabled = true;
          break;
        default:
          break;
      }
    }

    return { excludePlans, allOptionsDisabled };
  };

  const personalPlans = getExcludePlans(subscription);
  const workspacePlans = getExcludePlans(wsSubscription);

  const isWsLifetimePlan = wsSubscription && wsSubscription.subscriptionPlanId === WS_LIFETIME_PLAN_ID.toString();
  const isWsAnnualPlan = wsSubscription && wsSubscription.subscriptionPlanId === WS_YEARLY_PLAN_ID.toString();
  const isWsMonthlyPlan = wsSubscription && wsSubscription.subscriptionPlanId === WS_MONTHLY_PLAN_ID.toString();

  const personalExcludePlans = [...personalPlans.excludePlans];
  if (isWsAnnualPlan) {
    personalExcludePlans.push('Monthly', 'Annual');
  } else if (isWsMonthlyPlan) {
    personalExcludePlans.push('Monthly');
  }

  const isLifetimePlan = (subscription && subscription.subscriptionPlanId === PADDLE_LIFETIME_ID.toString())
    || (wsSubscription && wsSubscription.subscriptionPlanId === WS_LIFETIME_PLAN_ID.toString());

  const noSubscription = !subscription && !wsSubscription;

  return (
    <Box className={classes.workspacePlans}>
      <PlanCard
        name="Personal"
        prices={{ monthly: MONTHLY_PRICE, yearly: YEARLY_PRICE, lifetime: LIFETIME_PRICE }}
        advantages={['for 1 user']}
        monthlyPlanId={PADDLE_MONTHLY_ID}
        yearlyPlanId={PADDLE_YEARLY_ID}
        lifetimePlanId={PADDLE_LIFETIME_ID}
        excludePlans={personalExcludePlans}
        allOptionsDisabled={personalPlans.allOptionsDisabled || isWsLifetimePlan}
      />
      <PlanCard
        name="Team"
        prices={{ monthly: WS_MONTHLY_SEAT_PRICE, yearly: WS_YEARLY_SEAT_PRICE, lifetime: WS_LIFETIME_SEAT_PRICE }}
        advantages={['for team', 'manage team']}
        teamPlan
        monthlyPlanId={WS_MONTHLY_PLAN_ID}
        yearlyPlanId={WS_YEARLY_PLAN_ID}
        lifetimePlanId={WS_LIFETIME_PLAN_ID}
        excludePlans={workspacePlans.excludePlans}
        allOptionsDisabled={workspacePlans.allOptionsDisabled}
      />
      {(isLifetimePlan || noSubscription) && (
      <>
        <Divider sx={{ borderWidth: 1, height: '260px', margin: '0 14px' }} className={classes.divider} />
        <PlanCard
          name="Activation code"
          prices={{ monthly: 0, yearly: 0, lifetime: 0 }}
          monthlyPlanId={1}
          yearlyPlanId={1}
          lifetimePlanId={1}
          activateButton
        />
      </>
      )}
    </Box>
  );
};

export const SubscriptionPlans: React.FC<IProps> = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <WorkspacePlans />
    </Box>
  );
};

export default SubscriptionPlans;
