import React, {
  useState, useCallback, useMemo, useEffect,
} from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { debounce } from 'lodash';
import useWorkspaceData from '../hooks/useWorkspaceData';
import { setDefaultWorkspaceOptions, updateWorkspaceOption } from '../../api/workspaces';
import useWorkspaceOptions from '../hooks/useWorkspaceOptions';

interface IOptionsContext {
  options: {
    color: string,
    highlightOn: boolean,
    mergeSize: number,
    numberingOn: boolean,
    smartCrop: boolean,
    cropWidth: number,
    cropHeight: number,
    language: string,
    pagelessMode: boolean,
  },
  changeOption: (value: string | boolean | number, optionName: string) => void,
  addDefaultOptions: () => void,
}

export const OptionsContext = React.createContext<IOptionsContext>({
  options: {
    color: '',
    highlightOn: false,
    mergeSize: 5,
    numberingOn: false,
    cropWidth: 600,
    cropHeight: 400,
    smartCrop: true,
    language: '',
    pagelessMode: false,
  },
  changeOption: () => {},
  addDefaultOptions: () => {},
});

export const OptionsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const {
    workspaceOptions,
    fetchWorkspaceOptions,
    loading,
  } = useWorkspaceOptions();

  const { workspaceId } = useWorkspaceData();

  const [options, setOptions] = useState<IOptionsContext['options']>({
    color: '',
    highlightOn: false,
    mergeSize: 5,
    numberingOn: false,
    cropWidth: 600,
    cropHeight: 400,
    smartCrop: true,
    language: '',
    pagelessMode: false,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateOptionDebounced = useCallback(debounce(async (optionName: string, value: string | boolean | number) => {
    if (workspaceId) {
      console.log(optionName, value);
      await updateWorkspaceOption(workspaceId, optionName, value);
    }
  }, 300), [workspaceId]);

  const changeOption = useCallback((value: string | boolean | number, optionName: string) => {
    setOptions((prevOptions) => {
      const newOptions = {
        ...prevOptions,
        [optionName]: value,
      };
      updateOptionDebounced(optionName, value);
      return newOptions;
    });
  }, [updateOptionDebounced]);

  const addDefaultOptions = useCallback(async () => {
    if (!loading && workspaceId && !workspaceOptions) {
      await setDefaultWorkspaceOptions(workspaceId);
      await fetchWorkspaceOptions(workspaceId);
    }
  }, [fetchWorkspaceOptions, loading, workspaceId, workspaceOptions]);

  useEffect(() => {
    if (workspaceOptions) {
      setOptions({
        color: workspaceOptions.color || '',
        highlightOn: workspaceOptions.highlightOn || false,
        mergeSize: workspaceOptions.mergeSize || 5,
        numberingOn: workspaceOptions.numberingOn || false,
        cropWidth: workspaceOptions.cropWidth || 600,
        cropHeight: workspaceOptions.cropHeight || 400,
        smartCrop: workspaceOptions.smartCrop || true,
        language: workspaceOptions.language || '',
        pagelessMode: workspaceOptions.pagelessMode || false,
      });
    }
  }, [workspaceOptions]);

  const value: IOptionsContext = useMemo(() => ({
    options,
    changeOption,
    addDefaultOptions,
  }), [options, changeOption, addDefaultOptions]);

  return (
    <OptionsContext.Provider value={value}>
      {children}
    </OptionsContext.Provider>
  );
};

export default OptionsProvider;
