import React, { useEffect, useContext, useLayoutEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../providers/AuthProvider';

const DefaultScene: React.FC = () => {
  const { user, authLoaded, signInWithToken } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  useLayoutEffect(() => {
    const currentUrl = window.location.href;
    const urlParams = new URLSearchParams(new URL(currentUrl).search);
    const token = urlParams.get('token');

    if (token) {
      signInWithToken(token);
    }
  }, [location.search, signInWithToken]);

  useEffect(() => {
    if (!authLoaded) return;

    if (!user) {
      navigate('login');
    } else {
      navigate('get-started');
    }
  }, [user, authLoaded, navigate]);

  return null;
};

export default DefaultScene;
