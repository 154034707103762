import * as React from 'react';

import { makeStyles } from '@mui/styles';
import {
  Box,
  CircularProgress,
  Typography,
} from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { WorkspaceContext } from '../../providers/WorkspaceProvider';
import BrandingTab from './BrandingTab';
import StepsTab from './StepsTab';
import LocalizationTab from './LocalizationTab';

const useStyles = makeStyles(() => ({
  root: {

  },

  form: {
    width: '400px',
    marginTop: '40px',
  },

  nameInput: {
    width: '100%',
  },

  submit: {
    marginTop: '16px !important',
  },
}));

interface TabPanelProps {
  // eslint-disable-next-line react/require-default-props
  children?: React.ReactNode;
  index: number;
  value: number;
}

const CustomTabPanel = (props: TabPanelProps) => {
  const {
    children, value, index,
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

export const WorkspaceInfo: React.FC = () => {
  const classes = useStyles();

  const { loading } = React.useContext(WorkspaceContext);

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box className={classes.root}>
      <Typography fontSize={24} fontWeight={600} mb={3}>
        App Options
      </Typography>

      { loading ? <CircularProgress />

        : (
          <Box
            p={3}
            width="100%"
            minHeight="500px"
            sx={{
              backgroundColor: 'white', boxShadow: '0px 4px 6px rgba(46, 49, 51, 0.1)', borderRadius: '8px',
            }}
          >
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }} mb={3}>
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" TabIndicatorProps={{ style: { fontWeight: 600 } }}>
                <Tab label="Branding" />
                <Tab label="Steps" />
                <Tab label="Localization" />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <BrandingTab />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <StepsTab />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <LocalizationTab />
            </CustomTabPanel>
          </Box>
        ) }
    </Box>
  );
};

export default WorkspaceInfo;
